import React from 'react'
import * as st from '~/assets/styl/Landing.module.styl'
import LandingLogo from '../assets/img/logo.png'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import PdfButton from './PdfButton'

export default function Landing() {
  return (
    <section id="landing" className={st.landing}>
      <PdfButton />
      <div className={st.headerWrapper}>
        <div>
          <img src={LandingLogo} alt="Logo do Vila Jardim" />
          <p>
            Pronto Para Morar
            <span>Lançamento Recém Entregue</span>
          </p>
        </div>
        <AnchorLink to="#specs" title="Saiba mais!">
          Saiba mais
        </AnchorLink>
      </div>
    </section>
  )
}
