import React from 'react'
import * as st from '~/assets/styl/Newsletter.module.styl'

import PdfButton from './PdfButton'
import NewsLogo from '~/assets/img/logo.png'
import ContactForm from './ContactForm'

export default function Newsletter() {
  return (
    <section id="newsletter" className={st.newsletter}>
      <PdfButton />
      <div className={st.formWrapper}>
        <div className={st.logo}>
          <img src={NewsLogo} alt="Logo do Vila Jardim" />
        </div>
        <h1>
          Cadastre-se e <br />
          receba mais informações
        </h1>
        <ContactForm />
      </div>
    </section>
  )
}
