import React from 'react'
import { Head } from '~/components'
import AboutUs from '~/components/AboutUs'
import FloatingMenu from '~/components/FloatingMenu'
import Gallery from '~/components/Gallery'
import Landing from '~/components/Landing'
import Localization from '~/components/Localization'
import Newsletter from '~/components/Newsletter'
import SpecsAndPhotos from '~/components/SpecsAndPhotos'
import Whats from '~/components/Whats'

const Home = () => (
  <>
    <Head />
    <FloatingMenu />
    <Landing />
    <SpecsAndPhotos />
    <Localization />
    <Gallery />
    <Newsletter />
    <AboutUs />
  </>
)

export default Home
