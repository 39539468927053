import React from 'react'
import * as st from '../assets/styl/AboutUs.module.styl'
import aboutBg from '../assets/img/about-bg.jpg'
import FooterDivider from '../assets/img/footer-divider.png'
import TheraLogo from '../assets/img/thera.png'
import CopyDivider from '../assets/img/copy-divider.png'

export default function AboutUs() {
  return (
    <section
      id="about-us"
      className={st.aboutUs}
      style={{ backgroundImage: `url(${aboutBg})` }}
    >
      <div className={st.upperBlock}>
        <h1>
          Somos a Thera <br /> Empreendimentos
        </h1>
        <p>
          Nosso objetivo é valorizar a experiência do morar. Acreditamos que o
          lar é o reflexo de um estilo de vida, e vemos em cada empreendimento
          uma oportunidade de conectar as pessoas ao seu bairro, à sua cidade,
          ao seu lugar.
        </p>
        <p>
          Por isso focamos em cada etapa do processo construtivo: priorizamos a
          escolha de localizações estratégicas, trabalhamos com projetos de
          arquitetos renomados e investimos em tecnologia e na mais alta
          qualidade dos materiais em nossos imóveis.
        </p>
        <p>
          A Thera Empreendimentos se estabelece no mercado da construção civil
          em Florianópolis e São José, sustentada pela expertise do seu fundador
          no setor da incorporação imobiliária e execução de obras.
        </p>
      </div>
      <img src={FooterDivider} alt="Divisor" width={165} />
      <div className={st.bottomBlock}>
        <ul>
          <li>
            <h2>+ 130.000m²</h2>
            <span>
              {' '}
              De obras <br /> entregues
            </span>
          </li>
          <li>
            <h2>+ 550</h2>
            <span>
              {' '}
              Apartamentos <br /> entregues
            </span>
          </li>
          <li>
            <h2>+ 250</h2>
            <span>
              {' '}
              Salas comerciais <br /> e lojas entregues
            </span>
          </li>
          <li>
            <h2>+ 200</h2>
            <span>
              {' '}
              Projetos <br /> realizados
            </span>
          </li>
        </ul>
        <p>
          Construímos nossa história com competência e solidez, sempre com o
          compromisso de <br />
          entregar empreendimentos de excelência para nossos clientes
        </p>
      </div>
      <div className={st.footerBlock}>
        <div>
          <img src={TheraLogo} alt="Logo da Thera Empreendimentos" />
          <img className={st.divider} src={CopyDivider} alt="Divisor" />
          <a href="https://www.theraempreendimentos.com.br">
            www.theraempreendimentos.com.br
          </a>
        </div>
        <div className={st.misc}>
          <span>
            Matrícula RI - N゜R.3/144.464 - Registro de Imóveis de São José.
            Planta ilustrativa. Todas as imagens, móveis e objetos de decoração
            têm caráter ilustrativo, não fazendo parte integrante do contrato ou
            memorial descritivo. Os materiais de acabamento que serão entregues
            fazem parte do contrato ou do memorial descritivo. Projeto executivo
            em desenvolvimento, podendo sofrer pequenas alterações nas
            compatibilizações técnicas.
          </span>
        </div>
      </div>
    </section>
  )
}
