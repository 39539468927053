import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'
import requestData from '~/api'
import * as st from '~/assets/styl/ContactForm.module.styl'
import formatPhone from '../utils/formatPhone'

export default function ContactForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setphone] = useState('')
  const [cookies] = useCookies()

  function handleNameChange(event) {
    setName(event.target.value)
  }

  function handleEmailChange(event) {
    setEmail(event.target.value)
  }

  function handlePhoneChange(event) {
    setphone(formatPhone(event.target.value))
  }

  function handleSubmit(event) {
    event.preventDefault()
    requestData('post', 'conversions', {
      name,
      email,
      phone: phone.replace(/\D/g, ''),
      token_rdstation: '5f6e806520fed86a13d57ca585b0fa39',
      identificador: 'vilajardim-lp',
      c_utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') navigate('/obrigado')
      else alert(data.msg)
    })
  }

  return (
    <form onSubmit={handleSubmit} className={st.contactForm}>
      <label htmlFor="name">Nome</label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={handleNameChange}
        required
      />
      <label htmlFor="email">E-mail</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={handleEmailChange}
        required
      />
      <label htmlFor="phone">Telefone</label>
      <input
        type="tel"
        id="phone"
        value={phone}
        onChange={handlePhoneChange}
        required
        maxLength={15}
      />
      <div className={st.buttonWrapper}>
        <button type="submit">Quero receber mais informações</button>
      </div>
    </form>
  )
}
