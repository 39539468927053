import React from 'react'
import * as st from '~/assets/styl/PdfButton.module.styl'

export default function PdfButton() {
  return (
    <div className={st.pdfButton}>
      <span>Prospecto completo do produto.</span>
      <a href="/VILAJARDIN_PRESS_V10.pdf" target="_blank">
        <span className={`${st.iconPdf} icon-pdf`}></span>
        <span>Baixe aqui</span>
      </a>
    </div>
  )
}
