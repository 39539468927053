/* eslint-disable indent */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Slider from 'react-slick'
import ReactLightbox from './Lightbox'

import * as st from '~/assets/styl/Gallery.module.styl'
import '../assets/styl/slick.css'
import galleryBg from '~/assets/img/gallery-bg.jpg'

import Living01 from '~/assets/img/imagens/Living01.jpg'
import Living02 from '~/assets/img/imagens/Living02.jpg'
import Living03 from '~/assets/img/imagens/Living03.jpg'
import Quarto01 from '~/assets/img/imagens/Quarto01.jpg'
import Quarto02 from '~/assets/img/imagens/Quarto02.jpg'
import Quarto03 from '~/assets/img/imagens/Quarto03.jpg'
import VilaJardim1 from '~/assets/img/vila-jardim.jpg'
import VilaJardim2 from '~/assets/img/foto1.jpg'

import Living01Zoom from '~/assets/img/imagens/zoomImg/living01.jpg'
import Living02Zoom from '~/assets/img/imagens/zoomImg/living02.jpg'
import Living03Zoom from '~/assets/img/imagens/zoomImg/living03.jpg'
import Quarto01Zoom from '~/assets/img/imagens/zoomImg/quarto01.jpg'
import Quarto02Zoom from '~/assets/img/imagens/zoomImg/quarto02.jpg'
import Quarto03Zoom from '~/assets/img/imagens/zoomImg/quarto03.jpg'
import VilaJardim1Zoom from '~/assets/img/imagens/zoomImg/vilajardim01.jpg'
import VilaJardim2Zoom from '~/assets/img/imagens/zoomImg/vilajardim02.jpg'

import Ap02 from '~/assets/img/plantas/2dorms.jpg'
import Ap03 from '~/assets/img/plantas/3dorms.jpg'

import Ap02Zoom from '~/assets/img/plantas/zoomImg/2dorms.jpg'
import Ap03Zoom from '~/assets/img/plantas/zoomImg/3dorms.jpg'

const apartmentPhotos = [
  {
    img: Living01,
    desc: 'Living Apartamento',
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Living02,
    desc: 'Living Apartamento',
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Living03,
    desc: 'Living Apartamento',
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Quarto01,
    desc: 'Quarto do Apartamento',
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: Quarto02,
    desc: 'Quarto do Apartamento',
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: Quarto03,
    desc: 'Quarto do Apartamento',
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: VilaJardim1,
    desc: 'Vila Jardim',
    alt: 'Foto da entrada Vila Jardim',
  },
  {
    img: VilaJardim2,
    desc: 'Vila Jardim',
    alt: 'Foto do interior Vila Jardim',
  },
]

const blueprintPhotos = [
  {
    img: Ap02,
    desc: 'Duplex 2 dormitórios - Primeiro pavimento / Segundo pavimento',
    alt: 'Planta do apartamento 2 dormitórios - Primeiro e segundo pavimento',
  },
  {
    img: Ap03,
    desc: 'Duplex 3 dormitórios sendo 1 suíte - Primeiro pavimento / Segundo pavimento',
    alt: 'Planta do apartamento 3 dormitórios (uma suíte) - Primeiro e segundo pavimento',
  },
]

const apartmentPhotosZoom = [
  {
    img: Living01Zoom,
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Living02Zoom,
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Living03Zoom,
    alt: 'Foto da sala de estar e cozinha do apartamento',
  },
  {
    img: Quarto01Zoom,
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: Quarto02Zoom,
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: Quarto03Zoom,
    alt: 'Foto do quarto do apartamento',
  },
  {
    img: VilaJardim1Zoom,
    alt: 'Foto da entrada Vila Jardim',
  },
  {
    img: VilaJardim2Zoom,
    alt: 'Foto do interior Vila Jardim',
  },
]

const blueprintPhotosZoom = [
  {
    img: Ap02Zoom,
    alt: 'Planta do apartamento 2 dormitórios - Primeiro e segundo pavimento',
  },
  {
    img: Ap03Zoom,
    alt: 'Planta do apartamento 3 dormitórios (uma suíte) - Primeiro e segundo pavimento',
  },
]

function CustomNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        content: ' ',
        display: 'block',
        borderBottom: '2px solid #273b2f',
        borderRight: '2px solid #273b2f',
        height: '40px',
        width: '40px',
        transform: 'rotate(-45deg)',
        margin: '15px auto 0',
      }}
      onClick={onClick}
    />
  )
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        content: ' ',
        display: 'block',
        borderBottom: '2px solid #273b2f',
        borderRight: '2px solid #273b2f',
        height: '40px',
        width: '40px',
        transform: 'rotate(135deg)',
        margin: '15px auto 0',
      }}
      onClick={onClick}
    />
  )
}

export default function Gallery() {
  const [currentyGallery, setCurrentyGallery] = useState('apartment')
  const [images, setImages] = useState<Array<string>>()
  const [index, setIndex] = useState(0)

  function handleGalleryShow(event) {
    if (event.target.value === 'apartment') {
      setCurrentyGallery('apartment')
    } else {
      setCurrentyGallery('blueprint')
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }

  return (
    <section
      id="gallery"
      className={st.galleryContainer}
      style={{ backgroundImage: `url(${galleryBg})` }}
    >
      <h1>Galeria</h1>
      <div className={st.buttonWrapper}>
        <button
          type="button"
          id="apartment"
          value="apartment"
          className={currentyGallery === 'apartment' && st.active}
          onClick={handleGalleryShow}
        >
          Imagens
        </button>
        <button
          type="button"
          id="blueprint"
          value="blueprint"
          className={currentyGallery === 'blueprint' && st.active}
          onClick={handleGalleryShow}
        >
          Plantas
        </button>
      </div>
      <Slider {...settings}>
        {currentyGallery === 'apartment'
          ? apartmentPhotos.map((photo, index) => (
              <div className={st.slickDiv} key={index}>
                <img
                  src={photo.img}
                  alt={photo.alt}
                  onClick={() => {
                    setIndex(index)
                    setImages(apartmentPhotosZoom.map((i) => i.img))
                  }}
                />
                <p>{photo.desc}</p>
              </div>
            ))
          : blueprintPhotos.map((photo, index) => (
              <div className={st.slickDiv} key={index}>
                <img
                  src={photo.img}
                  alt={photo.alt}
                  onClick={() => {
                    setIndex(index)
                    setImages(blueprintPhotosZoom.map((i) => i.img))
                  }}
                />
                <p>{photo.desc}</p>
              </div>
            ))}
      </Slider>
      <ReactLightbox
        index={index}
        images={images}
        setIndex={setIndex}
        setImages={setImages}
      />
    </section>
  )
}

CustomNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

CustomPrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}
