import React, { useState } from 'react'
import * as st from '~/assets/styl/FloatingMenu.module.styl'
import closeIcon from '~/assets/img/icons/close-icon.png'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function FloatingMenu() {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const handleClose = () => {
    setNavbarOpen(false)
  }

  const navItems = [
    { name: 'Home', href: '#landing' },
    { name: 'Vila Jardim', href: '#specs' },
    { name: 'Localização', href: '#localization' },
    { name: 'Imagens e Plantas', href: '#gallery' },
    { name: 'Contato', href: '#newsletter' },
    { name: 'Sobre nós', href: '#about-us' },
  ]

  return (
    <nav className={st.navBar}>
      <button onClick={handleToggle}>
        <div className={st.bar1}></div>
        <div className={st.bar2}></div>
        <div className={st.bar3}></div>
      </button>
      <ul className={`${st.menuNav} ${navbarOpen ? `${st.showMenu}` : ''}`}>
        <button onClick={handleClose}>
          <img src={closeIcon} />
        </button>
        {navItems.map((item, index) => (
          <li style={item.name == 'Home' ? { marginTop: 80 } : {}} key={index}>
            <AnchorLink className={st.navItem} to={item.href}>
              <span onClick={handleClose}>{item.name}</span>
            </AnchorLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
