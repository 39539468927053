import React from 'react'
import * as st from '~/assets/styl/SpecsAndPhotos.module.styl'
import foto1 from '../assets/img/foto1.jpg'
import foto2 from '../assets/img/foto2.jpg'
import foto3 from '../assets/img/foto3.jpg'
import vilaJardim from '../assets/img/vila-jardim.jpg'

export default function SpecsAndPhotos() {
  const houseSpecs = [
    'Portas externas - madeira maciça;',
    'Portas internas - kit porta pronta;',
    'Infra-estrutura de espera de ar condicionado, tipo Split para os quartos;',
    'Parede dupla nos dormitórios entre casas vizinhas;',
    'Previsão de ponto para máquina de lavar roupas;',
    'Esquadrias de alumínio com vidro 6mm nos dormitórios;',
    'Acabamento interno de pintura com massa corrida;',
    'Banheiros e lavabos entregues com lavatório e bancada de granito.',
  ]

  const complexSpecs = [
    'Coleta seletiva de lixo;',
    'Peças sanitárias com baixo consumo;',
    'Wi-fi no salão de festas;',
    'Lâmpadas led nas áreas comuns;',
    'Medidores individuais de água;',
    'Salão de festas decorado, com churrasqueira a carvão e sistema de arcondicionado;',
    'Sistema de segurança com câmeras;',
    'Pet place.',
  ]

  return (
    <section id="specs">
      <div
        className={`${st.backgroundFixer} ${st.closedComplex}`}
        style={{
          backgroundImage: `url(${foto1})`,
        }}
        role="image"
        aria-label="Foto ilustrativa no interior do condomínio"
      >
        <div className={st.textPanel}>
          <h2>
            Onde a vida é mais feliz, com espaço e segurança para a sua família.
          </h2>
        </div>
        <span>Condomínio fechado</span>
      </div>
      <div
        className={`${st.backgroundFixer} ${st.liveWell}`}
        style={{
          backgroundImage: `url(${foto2})`,
        }}
        role="image"
        aria-label="Foto de uma menina escrevendo deitada no chão da sala"
      >
        <div className={st.textPanel}>
          <h2>
            Viva bem,
            <br />
            no Vila Jardim.
          </h2>
          <p>
            Ambientes com arquitetura diferenciada <br />
            em um condomínio fechado, com a <br />
            proteção ideal para aproveitar todos os <br />
            momentos do seu dia a dia. Viva conectado <br />
            ao melhor que a cidade oferece e a um <br />
            novo estilo de vida, com comunidade <br />
            própria.
          </p>
        </div>
      </div>
      <div
        className={`${st.backgroundFixer} ${st.unities}`}
        style={{
          backgroundImage: `url(${foto3})`,
        }}
        role="image"
        aria-label="Foto de uma família onde o pai e a mãe passam um tempo com a filha"
      >
        <div className={st.textPanel}>
          <h2>
            Duplex com <br />2 ou 3 dormitórios <br />1 vaga de garagem <br />
            Apenas 22 unidades
          </h2>
          <p>
            Escolha morar em unidades de 2 ou 3 <br />
            quartos, com layout de interiores muito <br />
            bem planejados. São apenas 22 casas <br />
            duplex com piso térreo e piso superior, 1 <br />
            vaga de garagem para cada unidade.
          </p>
          <p>
            Nas áreas comuns,{' '}
            <span>
              salão de festas <br />
              mobiliado
            </span>
            , com gramado externo para <br />
            crianças e <span>pet place</span>, para uso coletivo dos <br />
            moradores. Tudo com alto padrão <br />
            construtivo e qualidade de acabamento.
          </p>
        </div>
      </div>
      <div
        className={`${st.backgroundFixer} ${st.differences}`}
        style={{
          backgroundImage: `url(${vilaJardim})`,
        }}
        role="image"
        aria-label="Foto ilustrativa da entrada do Residencial Vila Jardim"
      >
        <div className={st.textPanel}>
          <h2>Principais Diferenciais das Casas</h2>
          <ul>
            {houseSpecs.map((spec, index) => (
              <li key={index}>{spec}</li>
            ))}
          </ul>
        </div>
        <div className={st.textPanel}>
          <h2>Principais Diferenciais do Condomínio</h2>
          <ul>
            {complexSpecs.map((spec, index) => (
              <li key={index}>{spec}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
