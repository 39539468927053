import React from 'react'
import * as st from '~/assets/styl/Localization.module.styl'
import localizacao from '../assets/img/localizacao.jpg'

export default function Localization() {
  return (
    <section>
      <div id="localization" className={st.localizationImage}>
        <img src={localizacao} alt="Localização do Vila Jardim" />
      </div>
      <div className={st.travelTime}>
        <h1>Distâncias de carro</h1>
        <div>
          <ul>
            <li>
              <span className={st.locationName}>Universidade Unisul</span>{' '}
              <span className={st.locationTime}>15 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>Arena Petry</span>
              <span className={st.locationTime}>5 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>Colégio Müller</span>
              <span className={st.locationTime}>5 minutos</span>
            </li>
          </ul>
          <ul>
            <li>
              <span className={st.locationName}>Passeio Pedra Branca</span>
              <span className={st.locationTime}>15 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>Shopping Continente</span>
              <span className={st.locationTime}>15 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>Shopping Via Catarina</span>
              <span className={st.locationTime}>7 minutos</span>
            </li>
          </ul>
          <ul>
            <li>
              <span className={st.locationName}>Supermercado Bistek</span>
              <span className={st.locationTime}>7 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>Mercado Brasil Atacadista</span>
              <span className={st.locationTime}>15 minutos</span>
            </li>
            <li>
              <span className={st.locationName}>CTG Os Praianos</span>
              <span className={st.locationTime}>5 minutos</span>
            </li>
          </ul>
        </div>
        <span className={st.timeExpected}>* Tempo aproximado</span>
      </div>
    </section>
  )
}
